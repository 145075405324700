import React, { useState, useEffect } from "react"
import { 
   Box, 
   Button,
   Center,
   Input,
   useToast,
   Textarea
} from "@chakra-ui/react"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   Midasi2,
   Midasi3,
} from '../util/functions.js';

 
const ContactPage = (pageprops) => {


   const toast = useToast();
   const [contactform, setcontactform] = useState({
      mailaddress : "",
      content : "",
      isDisable : false
   });


   const updataContactForm = (param) => {
      setcontactform({
         ...contactform,
         isDisable:true
      });
      var type="updataContactForm";
      if(param.mailaddress === ""){
         toast({
            title: "入力内容に不備があります",
            description: "メールアドレスを入力してください。",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
         });
         setcontactform({
            ...contactform,
            isDisable:false
         });
         return;
      }
      if(param.content === ""){
         toast({
            title: "入力内容に不備があります",
            description: "お問い合わせ内容を入力してください。",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
         });
         setcontactform({
            ...contactform,
            isDisable:false
         });
         return;
      }
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            toast({
               title: "お問い合わせ受付完了",
               description: "お問い合わせを承りました。",
               status: "success",
               duration: 5000,
               isClosable: true,
               position: "top",
            });
            setcontactform({
               ...contactform,
               isDisable:false
            });
         })
         .catch( (error) => {
            console.log(error);
            setcontactform({
               ...contactform,
               isDisable:false
            });
      });
   }

   useEffect(() => {

   }, []);

   

   return (
      <>
         <Layout uri={pageprops.uri}>
            <Midasi2>お問い合わせ</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">
               <Midasi3>返信先メールアドレス</Midasi3>
               <Input 
                  placeholder=""
                  bg="whiteAlpha.900"
                  onChange={(e) => setcontactform({
                     ...contactform,
                     mailaddress:e.target.value
                  })}
                  defaultValue="" 
               />
               <Midasi3>内容</Midasi3>
               <Textarea 
                  placeholder="お問い合わせ内容" 
                  bg="whiteAlpha.900"
                  onChange={(e) => setcontactform({
                     ...contactform,
                     content:e.target.value
                  })}
                  resize="vertical"
                  size="lg"
                  rows="10"
                  // size="10rem"
               />
               <Center>
                  <Button 
                     onClick={() =>updataContactForm(contactform)}
                     w="60%"
                     // rounded="full"
                     m={5} 
                     colorScheme="blue"
                     isDisabled = {contactform.isDisable}
                  >
                     お問い合わせ
                  </Button>
               </Center>
            </Box>
         </Layout>
      </>
   )
}

export default ContactPage